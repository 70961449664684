import { APIFilter } from '@/utils/api'
import { toDate, endOfDay, startOfDay } from '@/utils/date'

export default {
  async selectCita (Vue, filter, search, sorter, page, idempleado, idoperacioncrm=null) {
    const apiFilter = new APIFilter()
      .addExact('idempleado', idempleado)
    if (idoperacioncrm) {
      apiFilter
        .addExact('identificador', idoperacioncrm)
    }
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.cita.searchFilter(search))
    }
    if (filter.fcita.value) {
      apiFilter.addGTE(filter.fcita.field, startOfDay(toDate(filter.fcita.value)))
      apiFilter.addLTE(filter.fcita.field, endOfDay(toDate(filter.fcita.value)))
    }
    if (filter.ffin.value) {
      apiFilter.addGTE(filter.ffin.field, startOfDay(toDate(filter.ffin.value)))
      apiFilter.addLTE(filter.ffin.field, endOfDay(toDate(filter.ffin.value)))
    }
    const resp = await Vue.$api.call(
      'cita.select', {
        page,
        filter: apiFilter,
        sorter,
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectCitaRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idcita', pks)
    const resp = await Vue.$api.call('cita.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
